body {
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Light"), local("Poppins-Light"),
    url("./fonts/Poppins-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Regular"), local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Medium"), local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-semibold"), local("Poppins-semibold"),
    url("./fonts/Poppins-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

// Here you can add other styles
.c-sidebar-brand.d-md-down-none {
  background: #2540d8;
}
.c-sidebar {
  background-color: #0d1c2f;
}

.c-sidebar-nav.h-100.ps {
  background: #0d1c2f;
}

.c-sidebar-nav-link {
  color: white !important;
}

.c-sidebar-nav-link.c-active {
  color: #283042 !important;
  background: #6378eb !important;
}

.c-sidebar-nav-icon.c-active {
  color: #0d1c2f !important;
  background: white !important;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background: rgb(0 0 21 / 20%);
  opacity: 0.9;
}

.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #283042;
}

.c-sidebar-nav-dropdown-items {
  background-color: #05125d;
}

.dash {
  height: 120%;
  border-radius: 15px;
}

.hand {
  background-color: #152db3;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
